import { Input } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'src/app/core/api/apiProvider';
import { useUser } from 'src/app/core/feature-user/provider/userProvider';
import withNavbar from 'src/app/core/handlers/withNavbar';
import Button from 'src/app/ui/Button';

const ChangePassword: FunctionComponent<any> = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { userInfo, userAccountInfo } = useUser();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error('Las contraseñas no coinciden');
      return;
    }

    try {
      await api.post(
        `${import.meta.env.VITE_API_URL}/accounts/change-password/`,
        {
          old_password: oldPassword,
          new_password: newPassword,
        }
      );

      toast.success('Contraseña cambiada correctamente');
    } catch (error: any) {
      toast.error(
        'Error al cambiar la contraseña ' + error?.response?.data?.error
      );
    }
  };

  const pageContent = (
    <div className="container mx-auto">
      <div className=" bg-gray-800  rounded m-3 p-5">
        <div className="flex gap-5">
          <div className="min-w-[400px]">
            <div className="mb-2">
              <h2 className="text-lg font-bold">Usuario:</h2>
              <p>{userInfo?.username}</p>
            </div>
            <div className="mb-2">
              <h2 className="text-lg font-bold">Nombre público:</h2>
              <p>{userAccountInfo?.public_name}</p>
            </div>
            <div className="mb-2">
              <h2 className="text-lg font-bold">E-mail:</h2>
              <p>{userInfo?.email}</p>
            </div>
          </div>
          <div className="flex-grow">
            <form onSubmit={handleSubmit} className="flex flex-col gap-5">
              <Input
                label="Contraseña Actual"
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
              <Input
                label="Nueva Contraseña"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                minLength={8}
                required
              />
              <Input
                label="Confirmar Contraseña Nueva"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                minLength={8}
              />
              <Button type="submit" variant="primary">
                Cambiar contraseña
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  return withNavbar({ children: pageContent });
};

export default ChangePassword;
